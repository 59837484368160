<template>
  <base-tooltip-button :to="to" :small="small" :is-icon="iconOnly">
    <template v-slot:icon-name>
      {{ iconArrowUpCircle }}
    </template>
    <template v-if="tooltip" v-slot:toolTip>
      {{ tooltip }}
    </template>
  </base-tooltip-button>
</template>

<script>
import { iconArrowUpCircle } from "@/design/icon/iconConst";

export default {
  name: "NavigateBackButton",
  data() {
    return {
      iconArrowUpCircle: iconArrowUpCircle
    };
  },
  props: {
    to: {
      type: Object,
      default: undefined
    },
    tooltip: {
      type: String,
      default: undefined
    }
  },
  components: {
    BaseTooltipButton: () =>
      import("@/components/shared/base/BaseTooltipButton")
  },
  computed: {
    small() {
      return true;
    },
    iconOnly() {
      return true;
    }
  }
};
</script>
